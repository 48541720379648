import z, { ZodType } from 'zod';
import { ApiError } from '../errors';

export const validateWithSchema = <ZSchema extends ZodType>(
    schema: ZSchema,
    data: Record<string, any>
  ) => {
    const result = schema.safeParse(data);
    if (!result.success) {
      console.log('result ===>', result.error.errors);
      throw new ApiError(
        422,
        `Validation Error: ${result.error.errors.map((e) => `${e.path.join(',')} ${e.message}`)}`
      );
    }
  
    return result.data as z.infer<ZSchema>;
  };
  

  export const maxLengthPolicies = {
    name: 104,
    nameShortDisplay: 20,
    email: 254,
    password: 128,
    team: 50,
    slug: 50,
    domain: 253,
    domains: 1024,
    apiKeyName: 64,
    webhookDescription: 100,
    webhookEndpoint: 2083,
    memberId: 64,
    characterId: 64,
    eventType: 50,
    endpointId: 64,
    inviteToken: 64,
    expiredToken: 64,
    invitationId: 64,
    sendViaEmail: 10,
  };
  