import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";
export const env = createEnv({
  server: {
    // This is optional because it's only used in development.
    // See https://next-auth.js.org/deployment.
    NEXTAUTH_URL: z.string().url().optional(),
    AUTH_SECRET: z.string().min(1),
    GOOGLE_CLIENT_ID: z.string().min(1),
    GOOGLE_CLIENT_SECRET: z.string().min(1),
    GITHUB_OAUTH_TOKEN: z.string().min(1),
    DATABASE_URL: z.string().min(1),
    // SUPA_DATABASE_URL: z.string().min(1),
    RESEND_API_KEY: z.string().min(1),
    EMAIL_FROM: z.string().min(1),
    STRIPE_API_KEY: z.string().min(1),
    STRIPE_WEBHOOK_SECRET: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_BUCKET_NAME: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_URL: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_APP_URL: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PRO_MONTHLY_PLAN_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PRO_YEARLY_PLAN_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_BUSINESS_MONTHLY_PLAN_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_BUSINESS_YEARLY_PLAN_ID: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_BUCKET_NAME: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_URL: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string().min(1),
  },
  runtimeEnv: {
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    AUTH_SECRET: process.env.AUTH_SECRET,
    GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
    GITHUB_OAUTH_TOKEN: process.env.GITHUB_OAUTH_TOKEN,
    DATABASE_URL: process.env.DATABASE_URL,
    SUPA_DATABASE_URL: process.env.SUPA_DATABASE_URL,
    RESEND_API_KEY: process.env.RESEND_API_KEY,
    EMAIL_FROM: process.env.EMAIL_FROM,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    // Stripe
    STRIPE_API_KEY: process.env.STRIPE_API_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
    NEXT_PUBLIC_STRIPE_PRO_MONTHLY_PLAN_ID:
      process.env.NEXT_PUBLIC_STRIPE_PRO_MONTHLY_PLAN_ID,
    NEXT_PUBLIC_STRIPE_PRO_YEARLY_PLAN_ID:
      process.env.NEXT_PUBLIC_STRIPE_PRO_YEARLY_PLAN_ID,
    NEXT_PUBLIC_STRIPE_BUSINESS_MONTHLY_PLAN_ID:
      process.env.NEXT_PUBLIC_STRIPE_BUSINESS_MONTHLY_PLAN_ID,
    NEXT_PUBLIC_STRIPE_BUSINESS_YEARLY_PLAN_ID:
      process.env.NEXT_PUBLIC_STRIPE_BUSINESS_YEARLY_PLAN_ID,

    // oss

    ALICLOUD_OSS_ACCESS_KEY_ID: process.env.ALICLOUD_OSS_ACCESS_KEY_ID,
    ALICLOUD_OSS_ACCESS_KEY_SECRET: process.env.ALICLOUD_OSS_ACCESS_KEY_SECRET,
    ALICLOUD_OSS_REGION: process.env.ALICLOUD_OSS_REGION,
    ALICLOUD_OSS_BUCKET: process.env.ALICLOUD_OSS_BUCKET,
    ALICLOUD_OSS_DOMAIN: process.env.ALICLOUD_OSS_DOMAIN,
    ALICLOUD_OSS_PUBLIC_DIR: process.env.ALICLOUD_OSS_PUBLIC_DIR,
    // MS Azure
    MS_AZURE_SPEECH_KEY: process.env.MS_AZURE_SPEECH_KEY,
    MS_AZURE_SPEECH_REGION: process.env.MS_AZURE_SPEECH_REGION,
    MS_AZURE_SPEECH_ENDPOINT: process.env.MS_AZURE_SPEECH_ENDPOINT,

    // supa
    // SUPA_BUCKET_ENDPOINT: process.env.SUPA_BUCKET_ENDPOINT,
    // SUPA_BUCKET_REGION: process.env.SUPA_BUCKET_REGION,

    // SUPA_BUCKET_ACCESS_KEY: process.env.SUPA_BUCKET_ACCESS_KEY,
    // SUPA_BUCKET_SECRET_KEY: process.env.SUPA_BUCKET_SECRET_KEY,
    // SUPA_BUCKET_NAME: process.env.SUPA_BUCKET_NAME,
    NEXT_PUBLIC_SUPABASE_BUCKET_NAME: process.env.NEXT_PUBLIC_SUPABASE_BUCKET_NAME,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY

  },
});
