import { UserRole } from "@prisma/client";
import * as z from "zod";
import { maxLengthPolicies } from "./utils";

export const userNameSchema = z.object({
  name: z.string().min(3).max(32),
});

export const userRoleSchema = z.object({
  role: z.nativeEnum(UserRole),
});

 const characterName = z.string({
  required_error: 'Character name is required',
  invalid_type_error: 'Character name must be a string',
})
.min(2, 'Character name should have at least 2 characters')
.max(
  20,
  `Character name should have at most ${20} characters`
);


const characterId = z
  .string({
    required_error: 'Character id is required',
    invalid_type_error: 'Character id must be a string',
  })
  .min(1)
  .max(
    maxLengthPolicies.characterId,
    `Character id should be at most ${maxLengthPolicies.characterId} characters`
  );



const chatMessage = z.object({
  role: z.string(),
  content: z.string(),
})

const modelParams = z.object({
  min_p: z.number(),
  temperature: z.number(),
  rep_pen: z.number(),
  rep_pen_tokens: z.number(),
})

const chatDialog = z.object({
  uuid: z.string(),
  title: z.string(),
  messages: z.array(chatMessage),
})

const loreBook = z.object({
  title: z.string(),
  key: z.string(),
  value: z.string(),
})

export const characterCreateSchema = z.object({
  remoteId: z.optional(z.string()),
  displayName: z.string(),
  realName: z.string(),
  persona: z.optional(z.string()),
  scenario: z.optional(z.string()),
  avatar: z.optional(z.string()),
  shortDescription:  z.optional(z.string()),
  greeting: z.optional(z.string()),
  description:  z.optional(z.string()),
  gender: z.optional(z.any()),
  note: z.optional(z.string()),
  oneLine: z.optional(z.string()),
  loreBooks:  z.optional(z.array( loreBook )),
  galleryImages: z.optional(z.array(z.string())),
  ttsVoice: z.optional(z.string()),
  firstMessageOptions: z.optional(z.array(chatDialog)),
  tags: z.optional(z.array(z.string())),
  status: z.optional(z.enum(['ACTIVE', 'INACTIVE', 'DELETED'])),
  playerAvatar: z.optional(z.string()),
  playerName: z.optional(z.string()),
  playerPersona: z.optional(z.string()),
  modelBasePrompt: z.optional(z.string()),
  modelParams: z.optional( z.any()),
  authorId: z.optional(z.string()),
  authorName: z.optional(z.string()),
  authorAvatar: z.optional(z.string()),
  isImageBlur: z.optional(z.boolean()),
  isNSFW: z.optional(z.boolean()),
  like: z.optional(z.number()),
});


export const UserProfileUpdateSchema = z.object({
  nickname: z.optional(z.string()),
  bio: z.optional(z.string()),
  avatar: z.optional(z.string()),
  gender: z.optional(z.any()),
});


export const characterUpdateSchema =  characterCreateSchema

export const ConversationSchema = z.object({
  id: z.string(),
  remoteId: z.string(),
  userId: z.string(),
  characterId: z.string(),
  characterAvatar: z.optional(z.string()),
  characterName: z.optional(z.string()),
  messages: z.array(z.any()),
  model: z.optional(z.string()),
  modelSettings: z.optional(z.any()),
  viewSettings: z.optional(z.any()),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export const ConversationCreateSchema = z.object({
  remoteId: z.string(),
  userId: z.string(),
  characterId: z.string(),
  characterAvatar: z.optional(z.string()),
  characterName: z.optional(z.string()),
  messages: z.array(z.any()),
  model: z.optional(z.string()),
  modelSettings: z.optional(z.any()),
  viewSettings: z.optional(z.any()),
})

export const characterGetSchema = z.object({
  id: characterId,
})
