import { UserInfo } from "@prisma/client";
import { User } from "@supabase/supabase-js";

export function getUserNickname(user: User & {
    appUserProfile?: UserInfo
})  {
    if (user.appUserProfile) {
        return user.appUserProfile.nickname
    } else {
        return user.user_metadata.nickname || user.user_metadata.full_name || user.user_metadata.name || '杰克'
    }
}


export function getUserAvatar(user: User & {
    appUserProfile?: UserInfo
}) {
    if (user.appUserProfile) {
        return user.appUserProfile.avatar
    } else {
        return user.user_metadata.avatar_url || ''
    }
}



export function getUserBio(user: User & {
    appUserProfile?: UserInfo
}) {
    if (user.appUserProfile) {
        return user.appUserProfile.bio
    } else {
        return user.user_metadata.bio || ''
    }
}